.btn-logout{
        font-size: 15px;
        font-weight: 500;
        background: transparent;
        border: 2px solid white;
        border-radius: 20px;
        cursor: pointer;
        transition: all 300ms;
        padding: 10px 40px;
        color: white;
        outline: none;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    
    .btn-logout:hover{
        border: 2px solid black;
        background: white;
        opacity: 0.2;
        color: black;
    
    }

/*Responsive Design*/
@media screen and (max-width: 650px){

    .btn-logout{
        width: 100%;
    }


}
