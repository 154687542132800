.card-header-validarprofesional{
  background-image: linear-gradient(142deg, #643360, #6dae40);
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container-validarprofesional{
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 7em;
}

.form-validar-prof{
  caret-color: transparent;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  border: 1px solid #643360;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  width: auto;
  height: 30px;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  font-weight: 300;
  
}

.validar-profesional{
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid #643360;
  border-radius: 10px;
  margin-bottom: 1em;
}


.btn-validar-profesional{
    font-family: "Lato", sans-serif;
    width: 250px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0px 0px 20px 0px;
    padding: 0.775rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 0.7;    
    color: #fff;
    background-color: transparent;
    border-color: #643360;
    font-weight: 500;
    margin-top: 0.5em;
  }

.btn-validar-profesional:hover{
    border: 2px solid #643360;
    background: rgba(109, 174, 64, .6);
    color: #643360;
    font-weight: 600;
  }
  
@media (max-width: 400px) {
    .form-validar-prof{
      height: 30px;
      font-size: 18px;
      width: 280px;
    }
    .btn-validar-profesional{
        width: 85%;
      }
    .container-validarprofesional{
        max-width: 340px;
      }
    .validar-profesional{
      width: auto;
    }

}