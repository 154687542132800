/*Boton Registro*/
.salud_deporte{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    margin: auto;
    height: auto;
    margin-top: 15px;
}

.salud_deporte h2{
    font-weight: bold;
    /* font-size:smaller; */
    color: #6dae40;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.estilo_vida{
    background-color: #6dae40;
    justify-content: center;
    align-items: center;
    display: row;
    margin: auto;
    height: auto;

}

.estilo_vida h2{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    padding-top: 10px;
}


.boton-registro {
    
    font-size: 20px;
    font-weight: 700;
    background: white;
    border: 2px solid #643360;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms;
    padding: 10px 40px;
    color: #6dae40;
    outline: none;
    transition: all 300ms;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.boton-registro:hover{
    border: 2px solid #643360;
    background: #643360;
    color: #6dae40;

}

.container{
    margin:auto;
}


/*Modal Registrarse*/
/*Abierto*/
.m-dialog-2{
    position: absolute;
    top: 20%;
    left: 34%;
    text-align: center;
    transform : translate(0%,0%);   
    width: min(100% - 15px, 840px);
    height: auto;     
    margin-inline: auto;  
    background:rgba(109, 174, 64, .4);
    transition: all 2s;
    border-radius: 20px;
    z-index: 1000;    
    border: 2px solid transparent;
    padding: 30px; 
}
/*Cerrado*/
.m-dialog-22{
    top: 100%;
    position : fixed;
    left: 0%;
    height: 100%; 
    width: 100%; 
    background:rgba(109, 174, 64, .6);
    padding: 50px; 
    z-index: 1000;
    border-radius: 20px;
    border: 2px solid #643360;
}
/*Background*/
/*Abierto*/
.background{
    top: 100%;
    position : fixed;
    left: 0%;
    height: 100%; 
    width: 100%; 
    transform : translate(0%,-100%);      
    background: black;
    opacity: 0.3;
    transition: all 0.5s;     
    z-index: 100;       
}
/*Cerrado*/
.background2{
    top: 100%;
    position : fixed;
    left: 0%;
    height: 100%; 
    width: 100%; 
    background: black;
    opacity: 0.8; 
    z-index: 100;
}

/*Responsive Design*/
@media screen and (max-width: 1025px){
    .m-dialog-2{
        top: 25%;
        left: 10%;
        height: auto; 
        width: 80%; 

    }
}

@media screen and (max-width: 325px){
    .m-dialog-2{
        top: 20%;
        left: 0%;
        height: auto; 
        width: 100%; 

    }
}


/*Iconos redes*/
/* 
.wrapper{
	display: inline-flex;
}
.wrapper .icon{
	margin: 0 5px;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 2;
	transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span{
	display: block;
	height: 40px;
	width: 40px;
	background: #fff;
	border-radius: 50%;
	position: relative;
	z-index: 2;
	box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
	transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span i{
	line-height: 40px;
	font-size: 15px;
}
.wrapper .icon .tooltip{
	position: absolute;
	bottom: 0;
	z-index: 1;
	background: #fff;
	color: #fff;
	padding: 2px 4px;
	font-size: 10px;
	font-weight: 500;
	border-radius: 15px;
	opacity: 0;
	pointer-events: none;
	box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
	transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip{
	bottom: -19px;
	opacity: 1;
	pointer-events: auto;
}

.wrapper .icon:hover span{
	color: #fff;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip{
	text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}
.wrapper .facebook:hover span,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip:before{
	background: #3B5999;
}

.wrapper .instagram:hover span,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip:before{
	background: #E1306C;
}

.wrapper .whatsapp:hover span,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip:before{
	background: #075E54;
}

#instagram {
	text-decoration: none;
	color:#E1306C
}

#whatsapp {
	text-decoration: none;
	color:#075E54
} */