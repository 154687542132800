.ofer-pend-validar{
    background-image: url("../../../../assets/avcare_1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow-x: hidden;
    height: 100vh;
}

/*Titulo*/
.ofer-pend-validar-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    margin-top: 1.5em;
    margin-bottom: 3em;
    font-size: 35px;
    font-weight: 700;
}

/*Cajas de ofertas*/
/*nombre de caja*/
.btn-admin-validar{
    justify-content: center;
    align-items: center;
    background:  rgba(147, 143, 149, 0.95);
    border: 3px solid #643360;
    border-radius: 15px;
    cursor: pointer;
    transition: all 300ms;
    color: black;
    width: fit-content;
    margin: auto;
    margin-bottom: 7em;
    margin-top: -5em;
    padding: 2em;
}

.btn-admin-validar:hover{
    border: 3px solid #643360;
    border-radius: 30px;
    background: rgba(109, 174, 64, .85);
    color: black;
    box-shadow: 0px 15px 20px rgba(109, 174, 64, .7);
}

/*Contenido de caja*/

.card-body-validar{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}

.card-header-validar{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}


/*Boton aceptar*/
.btn-aceptar{
    margin-top: 1em;
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border: 2px solid #6dae40;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms;
    padding: 10px 40px;
    color: #6dae40;
    margin: 1em auto;
}

.btn-aceptar:hover{
    border: 2px solid #6dae40;
    background: rgba(109, 174, 64, .6);
    color: #643360;
    font-weight: 700;
}

/*Ocultar scroll bars*/
::-webkit-scrollbar {
    display: none;
}

