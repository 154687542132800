* {
    box-sizing: border-box;
  }
  
.comp-prof{
    background-image: linear-gradient(142deg, #643360, #6dae40);
    font-family: "Lato", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.container-comp-prof{
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    margin: 2%;
    margin-bottom: 6.5em;
  }

.form-comp-prof input{
    caret-color: transparent;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    border: 1px solid #643360;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    width: 300px;
    height: 30px;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    font-weight: 300;

  }

#descripcion  {
    resize:none;
    opacity: 0.8;
    border: solid 2px black;
    width: 80%;
}

#experiencia {
    resize:none;
    opacity: 0.8;
    border: solid 2px black;
    width: 80%;
    border-radius: 10px;

}


#profesion1{  
  margin-top: -5px;
  cursor: pointer;
  width: 20px;
  height: 20px;

}

#profesion2{  
  margin-top: -5px;
  cursor: pointer;
  width: 20px;
  height: 20px;

}


.btn-comp-prof{
    font-family: "Lato", sans-serif;
    width: 250px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0px 0px 20px 0px;
    padding: 0.775rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 0.7;    
    color: #fff;
    background-color: transparent;
    border-color: #643360;
    font-weight: 500;
    margin-top: 0.5em;
  }

.btn-comp-prof:hover{
    border: 2px solid #643360;
    background: rgba(109, 174, 64, .4);
    color: #643360;
    font-weight: 600;
  }
  
@media (max-width: 400px) {
    .form-comp-prof input{
      height: 30px;
      font-size: 18px;
      width: 280px;
    }
    .btn-comp-prof{
        width: 85%;
      }
    .container-comp-prof{
        max-width: 340px;
      }

}