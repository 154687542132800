/*Formularios*/
.contenedor__sign-up{
    display: flex;
    align-items: center;
    width: 100%;
	height: auto; 
    background: #6dae40;
    padding: 20px 50px;             
    margin: auto;
    border-radius: 20px;
    border: 2px solid #643360;
}

/*Casillas solicitud de datos*/
.contenedor__sign-up form input{
    width: 100%;
    margin-top: 1em;
    border: 1.5px solid #643360;
    background-color: white;
    border-radius: 10px;   
    text-align: center;
    font-size: 15px;
}

/*Letras acepto terminos*/
.contenedor__sign-up form h2{
    justify-content: center;
    font-size: 20px;
    text-align: center;
    margin-top: 1em;
    color: white;
}

/*Boton registrarse*/
.contenedor__sign-up form button{
    padding: 10px 40px;
    margin: auto;
    border: 2px solid #643360;
    font-size: 20px;
    background: white;
    font-weight: 700;
    color: #6dae40;
    cursor: pointer;
    outline: none;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;    
    transition: all 300ms;
}

.contenedor__sign-up form button:hover{
    border: 2px solid #643360;
    background: #643360;
    color: #6dae40;
}

.contenedor__check-box{
    font-size: 20px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    border: none;
    background: #d4d0d0;
    color: black;
}

/*Responsive Design*/
@media screen and (max-width: 1025px){

    .contenedor__sign-up{
        top: 15%;
        padding: 20px 10px;             
        margin: auto;
        border-radius: 20px;
        border: 2px solid #643360;

    }

}