* {
    box-sizing: border-box;
  }
  
  html,
  body,
  .wrapper {
    height: 100%;
  }
  
  body {
    margin: 0;
    background: #f0f2f5;
    font-family: system-ui, -apple-system, BlinkMacSystemFont;
  } 

button {
    display: grid;
    place-items: center;
    background: transparent;
    color: #606770;
    border: 0;
  }
  
button > i {
    font-size: 20px;
  }

.footer-navbar{
    position: fixed;
    bottom:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content: space-between;
    height: 92px;
    width: 100%;
    padding: 0 15px;
    border-bottom: 1px solid #e8e8e8;
    background: #f0f2f5;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f0f2f5;
  }

.navbar-badge {
    position: absolute;
    z-index: 1;
    top:8px;
    right: 54px;
    background: #e41e3f;
    color: #f9f9f9;
    font-size: 12px;
    padding: 1px 4px;
    border-radius:10px;
    transition: 0.3s;
}

.navbar-center {
    display: flex;
    flex: 1 1 auto;
    padding: 0 10px;
}

.navbar-center > button {
    flex: 1 1 auto;
    height: 72px;
    padding-top: 4px;
    border-top: 4px solid transparent;
}

.navbar-center > button:hover {
    border-top-color: #6dae40;
    color: #643360;
}

.navbar-center > button.active > i {
    border-top-color: #6dae40;
}

.navbar-center > button > i {
    font-size: 24px;
}

.navbar-end {
    display: flex;
    gap: 6px;
    flex: 0 0 60px;
    height: px;
  }

  .navbar-end > ul {
    flex: 1 1 auto;
    display:flex;    
    padding-top: 4px;
    border-bottom: 4px solid transparent;
    transform : translate(0px,-114px);     
}

@media (max-width: 480px){
  .footer-navbar {
    padding-left: 1px;        
  }
  .navbar-center {
    padding-left: 1px;
    padding-right: 5px;    
}  
}
