* {
    box-sizing: border-box;
    margin:0;

  }
  
.chg-pwd{
    background-image: linear-gradient(142deg, #643360, #6dae40);
    font-family: "Lato", sans-serif;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
}

.container_chg-pwd{
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 30px;
    max-width: 1000px;
    text-align: center;
  }

.form-chg-pwd input{
    caret-color: transparent;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    border: 1px solid #643360;
    font-size: 20px;
    font-family: "Lato", sans-serif;
    width: 300px;
    height: 40px;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    font-weight: 300;

  }

.btn-actpwd{
    font-family: "Lato", sans-serif;
    width: 250px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: 1.5px solid transparent;
    margin: 0px 0px 20px 0px;
    padding: 0.775rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 0.7;    
    color: #fff;
    background-color: transparent;
    border-color: #643360;
    font-weight: 500;
    margin-top: 2em;
  }

.btn-actpwd:hover{
    border: 2px solid #643360;
    background: rgba(109, 174, 64, .4);
    color: #643360;
    font-weight: 600;
  }
  
@media (max-width: 400px) {
    .form-chg-pwd input{
      height: 30px;
      font-size: 18px;
      width: 280px;
    }
    .btn-actpwd{
        width: 85%;
      }
    .container_chg-pwd{
        max-width: 340px;
      }

}