.of-dis{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    color: #643360;
    width: auto;
    margin: auto;
    height: auto;
    margin-top: 1.5em;
}

#servicios{
    display: flex;
    justify-content:center;
    align-items:center;
    width: auto;
    margin: auto;
    height: auto;
}

.service-nutr {
	background-image: url(./img/nutricion.webp);
    display: flex;
    justify-content: center;
    outline:2px solid #643360;
    border-radius: 8px;
    height: 288px;
    width: 512px;
    margin-top: 2em;
    margin-bottom: 2em;

}

.service-train {
    background-image: url(./img/per_trainer.webp);
    display: flex;
    justify-content: center;
    outline:2px solid #643360;
    border-radius: 8px;     
    margin-top: 2em;
    margin-bottom: 2em;
    height: 288px;
    width: 512px;
  
}

.service-paq {
    background-image: url(./img/paq_salud.webp);
    display: flex;
    justify-content: center;
    outline:2px solid #643360;
    border-radius: 8px;   
    margin-top: 2em;
    margin-bottom: 7em;
    height: 288px;
    width: 512px;
}

@media screen and (max-width: 530px){

    .service-nutr {
        width: 368px;
        height: 184px;
        background-position:center;   

    }

    .service-train {
    width: 368px;
    height: 184px;
    background-position:center;      
    }

    .service-paq {
        width: 368px;
        height: 184px;
        background-position:center;
    }

    .of-dis{
        font-size: 30px;

    }

}

/*Ocultar scroll bars*/
::-webkit-scrollbar {
    display: none;
}