 * {
    box-sizing: border-box;
  }
  
.cod-confirm {
    background-image: linear-gradient(142deg, #643360, #6dae40);
    font-family: "Lato", sans-serif;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
  } 
  
  .container{
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 30px;
    max-width: 1000px;
    text-align: center;
  }
  
  .code-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
  }
  
  .code{
    caret-color: transparent;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    border: 1px solid #eee;
    font-size: 30px;
    font-family: "Lato", sans-serif;
    width: 75px;
    height: 80px;
    margin: 10px;
    text-align: center;
    font-weight: 300;
  }
 

  .btn-primary{
    font-family: "Lato", sans-serif;
    width: 400px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0px 0px 20px 0px;
    padding: 0.775rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 0.7;    
    color: #fff;
    background-color: transparent;
    border-color: #643360;
    font-weight: 500;
  }

  .btn-primary:hover{
    border: 2px solid #643360;
    background: rgba(109, 174, 64, .4);
    color: #643360;
    font-weight: 600;
  }
  
  @media (max-width: 767px) {
    .code-container{
      flex-wrap: wrap;
      width: 100%;
    }
    .code{
      font-size: 24px;
      height: 50px;
      max-width: 45px;
      font-size: bold;
      margin: 5px;       
    }
    .btn-primary{
        width: 80%;
      }
    .container{
        max-width: 360px;
      }


  }

  @media (max-width: 424px) {
    .code-container{
      flex-wrap: wrap;
      width: 100%;
    }
    .code{
      font-size: 24px;
      height: 40px;
      max-width: 40px;
      font-size: bold;
      margin: 5px;      
    }
    .btn-primary{
        width: 80%;
      }
    .container{
        max-width: 360px;
      }


  }  
  
  .code::-webkit-outer-spin-button,
  .code::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  .code:valid {
    border-color: #643360;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
  }
