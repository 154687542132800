.servicios-profesional{
    background-image: url("../../../../assets/avcare_1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow-x: hidden;
    height: 100vh;
}

/* .ofertas-entrenador{
    /* background-image: url("../../../../assets/avcare_1.jpg"); */
    /* background-image: linear-gradient(142deg, #6dae40, #643360);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow-x: hidden;
    height: 100vh;
} */

/*Tipos de oferta*/ 
#offertype-trainer{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    width: fit-content;
    margin: auto;
    margin-bottom: 5em;
    background-color: #6dae40;
    color: white;
    border-radius: 15px;
    border: 3px solid #643360;
}

.option{
    border: 3px solid #643360;
}


/*Cajas de ofertas*/
.boton-trainer{
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border: 3px solid #643360;
    border-radius: 15px;
    cursor: pointer;
    transition: all 300ms;
    color: black;
    width: fit-content;
    padding: 1em;
    margin: auto;
    margin-bottom: 7em;
    margin-top: -6em;
}

.boton-trainer:hover{
    border: 3px solid #643360;
    border-radius: 30px;
    background: rgba(109, 174, 64, .9);
    color: black;
    box-shadow: 0px 15px 20px rgba(109, 174, 64, .7);
}

/*Contenido caja oferta*/
.card-trainer{
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1em;
    margin: auto;
}


.card-body-trainer{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}

.card-header-trainer{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}

/*Imagenes*/
.offer-nutri {
    display: flex;
    justify-content: center;
    align-items: center;
}

.showcase img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 2em;
    opacity: 1;
}


/*Oferta Entrenamiento*/  
.offer-train {
	background-image: url(./img/per_trainer.webp);
    opacity: 1;  
    display: flex;
    justify-content: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 2em;
    opacity: 1;    
}


/*Oferta Paquete*/  

.offer-paq{
    background-image: url(./img/paq_salud.webp);
    opacity: 1;  
    display: flex;
    justify-content: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 2em;
    opacity: 1;  

}


@media screen and (max-width: 530px){
    #offertype-trainer{
        font-size: 25px;

    }

    .showcase img {
        width: 368px;
        height: 184px;
        background-position:center;
    }

    .offer-train {
        width: 368px;
        height: 184px;
        background-position:center; 
    }

    .offer-paq{
        width: 368px;
        height: 184px;
        background-position:center; 
    }


}

/*Ocultar scroll bars*/
::-webkit-scrollbar {
    display: none;
}