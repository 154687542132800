.style-loader
{
    margin: 0;
    /* display: grid; */
    place-items:center;
    background:#1a1a1a;
    height:"100%";
    /* background-image: linear-gradient(142deg, #643360, #6dae40); */
    font-family: "Lato", sans-serif;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;    
}

.style-svg {
    width: 20vw;
    height: 20vw;
    animation: 
    spin 1s linear infinite;
}

@keyframes spin {
    100% {
        rotate: 360deg;
}
}

@keyframes dash { 
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }    
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }    
} 

circle {
    fill: none;
    stroke: #6dae40;
    stroke-width: 3px;
    stroke-linecap: round;
    animation:
    dash 2.5s ease-in-out infinite;
}