
/*Formularios*/
.contenedor__login-register{
    display: flex;
    align-items: center;
    justify-content: center;
}

#username{
    border: 2px solid #6dae40;
    border-radius: 20px;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;

}

#username::placeholder{
    color:gray;
    font-weight: bold;
    
}


#password{
    border: 2px solid #6dae40;
    border-radius: 20px;
    width: 100%;
    text-align: center;
}

#password::placeholder{
    color:gray;
    font-weight: bold;
}

.chgpwd{
    margin: 10px;
    text-align: center;
    font-weight: 500;
}

.chgpwd a{
    color: #6dae40;
    text-decoration: none;
}

.chgpwd a:hover{
    font-weight: 750;
    color: #6dae40;    
    text-decoration: none;
}

.fgtpwd{
    margin: -5px;
    text-align: center;
    font-weight: 500;
}

.fgtpwd a{
    color: #6dae40;
    text-decoration: none;
}

.fgtpwd a:hover{
    font-weight: 750;
    color: #6dae40;    
    text-decoration: none;
}

.boton-entrar {
    
    font-size: 20px;
    font-weight: 700;
    background: transparent;
    border: 2px solid #6dae40;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms;
    padding: 10px 40px;
    color: #6dae40;
    outline: none;
    transition: all 300ms;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;  

}

.boton-entrar:hover{
    border: 2px solid #643360;
    background: rgba(109, 174, 64, .8);
    color: #643360;

}

/*Responsive Design*/
@media screen and (max-width: 600px){
    main{
        margin-top: 50px;
    }

    /*Formulario Responsive*/
    .contenedor__login-register{
        top: -10px;
        left: 5px;
        margin: auto;
    }

    .contenedor__login-register form{
        position: relative;
    }

    #username{
        border: 2px solid #6dae40;
        border-radius: 20px;
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    
    }

}
