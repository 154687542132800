.prof-pend-validar{
    background-image: url("../../../../assets/avcare_1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow-x: hidden;
    height: 100vh;
}

.prof-pend-validar-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    margin-top: 1.5em;
    margin-bottom: 3em;
    font-size: 35px;
    font-weight: 700;
}

.verificar-profesionales{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(151, 144, 154, 0.95);
    border-radius: 30px;
    width: fit-content;
    margin: auto;
    border: 4px solid #643360;
    padding: 20px;
    text-align: center;
    margin-bottom: 2em;
}

.btn-detalles-vp{
    font-family: "Lato", sans-serif;
    width: 250px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: 3px solid transparent;
    padding: 0.775rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 0.7;    
    color: #643360;
    background-color: transparent;
    border-color: #643360;
    font-weight: 600;
    margin-top: 0.5em;
}

.btn-detalles-vp:hover{
    background: rgba(109, 174, 64, .8);
  }


/*Ocultar scroll bars*/
::-webkit-scrollbar {
    display: none;
}