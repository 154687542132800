.servicios-usuario{
    background-image: url("../../../../assets/avcare_1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow-x: hidden;
    height: 100vh;
}

/*Tipos de oferta*/ 
#offertype-user{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    width: fit-content;
    margin: auto;
    margin-bottom: 1em;
    background-color: #6dae40;
    color: white;
    border-radius: 15px;
    border: 3px solid #643360;
}

/*Oferta personalizada*/
.ofer-personalizada{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    width: fit-content;
    margin: auto;
    height: fit-content;
    margin-bottom: 6em;
    background-color: #6dae40;
    color: white;
    border-radius: 20px;
    border: 3px solid #643360;
    box-shadow: 0px 10px 10px rgba(109, 174, 64, .7);
}

/*Modal oferta personalizada*/
.modal-ofer-personalizada{
    position: absolute;
    top: 40%;
    left: 35%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: auto; 
    width: 30%; 
    transform : translate(0%,0%);     
    background:#643360;
    padding: 30px; 
    z-index: 1000;
    transition: all 1s;
    border-radius: 20px;
}

/*Cajas de ofertas*/
.boton-user{
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border: 3px solid #643360;
    border-radius: 15px;
    cursor: pointer;
    transition: all 300ms;
    color: black;
    width: fit-content;
    margin: auto;
    margin-bottom: 7em;
    margin-top: -6em;
    padding: 1em;
}

.boton-user:hover{
    border: 3px solid #643360;
    border-radius: 30px;
    background: rgba(109, 174, 64, .9);
    color: black;
    box-shadow: 0px 15px 20px rgba(109, 174, 64, .7);
}

/*Contenido caja oferta*/
.card-user{
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0;
}

.card-body-user{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}

.card-header-user{
    background: transparent;
    border: 1px solid #643360;
    padding: 1em;
    border-radius: 3px;
}

/*Imagenes*/
.offer-nutri {
    display: flex;
    justify-content: center;
    align-items: center;
}

.showcase img {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 1em;
    opacity: 1;
}


/*Oferta Entrenamiento*/  
.offer-train {
	background-image: url(./img/per_trainer.webp);
    opacity: 1;  
    display: flex;
    justify-content: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 1em;
    opacity: 1;    
}

/*Oferta Paquete*/  

.offer-paq{
    background-image: url(./img/paq_salud.webp);
    opacity: 1;  
    display: flex;
    justify-content: center;
    margin: auto;
    width: 512px;
    height: 256px;
    border: 3px solid #643360;
    border-radius: 10px;
    margin-top: 1em;
    opacity: 1;  

}


@media screen and (max-width: 530px){
    #offertype-user{
        font-size: 25px;

    }

    .ofer-personalizada{
        width: 90%;
    }

    .showcase img {
        width: 368px;
        height: 184px;
        background-position:center;
    }

    .offer-train {
        width: 368px;
        height: 184px;
        background-position:center; 
    }

    .offer-paq{
        width: 368px;
        height: 184px;
        background-position:center; 
    }


}

/*Ocultar scroll bars*/
::-webkit-scrollbar {
    display: none;
}