#detoferta{
    resize:none;
    opacity: 0.8;
    border: solid 2px black;
    width: 80%;
    border-radius: 5px;
}

#franjahoraria{
    opacity: 0.8;
    border: solid 2px black;
    width: 80%;
    border-radius: 5px;
    text-align: center;
}


.m-dialog-opened{
    position : fixed;
    top: 100%;
    left: 0%;
    height: 100%; 
    width: 100%; 
    transform : translate(0%,-80%);     
    background:#6dae40;
    padding: 30px; 
    /* background: rgba(#000,.9); */
    z-index: 1000;
    transition: all 1s;
    border-top-left-radius: 20px; 
    border-top-right-radius: 20px;
}

/*Modal aplicar oferta usuarios*/
.m-reg-dialog-opened{
    position: absolute;
    top: 40%;
    left: 35%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: auto; 
    width: 30%; 
    transform : translate(0%,0%);     
    background:#643360;
    padding: 30px; 
    z-index: 1000;
    transition: all 1s;
    border-radius: 20px;     
}

/*Modal aplicar oferta profesionales*/
.m-apl-dialog-opened{
    position: absolute;
    top: 25%;
    left: 30%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: auto; 
    width: 40%; 
    transform : translate(0%,0%);     
    background:#643360;
    padding: 30px; 
    z-index: 1000;
    transition: all 1s;
    border-radius: 20px;  
}

/*Modal Cerrar Sesion*/
.m-logout-dialog-opened{
    position: absolute;
    top: 25%;
    left: 40%;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    height: auto; 
    width: 20%; 
    transform : translate(0%,0%);     
    background:#643360;
    padding: 30px; 
    z-index: 1000;
    transition: all 1s;
    border-radius: 20px;  
}

.background-opened{
    top: 0;
    position : fixed;
    left: 0;
    height: 100vh; 
    width: 100%; 
    background: black;
    opacity: 0.2;
    transition: all 0.5s;     
    z-index: 100;       
}


.m-dialog-closed{
    top: 100%;
    position : fixed;
    left: 0%;
    height: 100%; 
    width: 100%; 
    background:#6dae40;
    padding: 50px; 
    z-index: 1000;

}

.background-closed{
    top: 100%;
    position : fixed;
    left: 0%;
    height: 100%; 
    width: 100%; 
    background: black;
    opacity: 0.6; 
}

.Boton{
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border: 2px solid #643360;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms;
    padding: 10px 40px;
    color: #6dae40;
    outline: none;
    transition: all 300ms;


}

.btn-apply{
    margin-top: 1em;
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border: 2px solid #6dae40;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms;
    padding: 10px 40px;
    color: #6dae40;
    margin: 1em auto;
}

.btn-apply:hover{
    border: 2px solid #6dae40;
    background: rgba(109, 174, 64, .6);
    color: #643360;
    font-weight: 700;
}

/*Responsive Design*/
@media screen and (max-width: 720px){
    .m-reg-dialog-opened{
        top: 40%;
        left: 10%;
        width: 80%; 

    }
}

/* @media screen and (max-width: 350px){
    .m-reg-dialog-opened{
        top: 40%;
        left: 10%;
        height: 45%; 
        width: 80%; 

    }
} */

@media screen and (max-width: 720px){
    .m-apl-dialog-opened{
        top: 15%;
        left: 10%;
        width: 80%; 
 
    }
}

@media screen and (max-width: 650px){
.m-logout-dialog-opened{
    top: 20%;
    left: 10%;
    width: 80%;
    font-size: 20px;
}
}

/* @media screen and (max-width: 420px){
    .m-apl-dialog-opened{
        top: 2%;
        left: 10%;
        width: 80%; 

    }
}

@media screen and (max-width: 380px){
    .m-apl-dialog-opened{
        top: 2%;
        left: 10%;
        width: 80%; 

    }
}

@media screen and (max-width: 320px){
    .m-apl-dialog-opened{
        top: 2%;
        left: 10%;
        height: 130%; 
        width: 80%; 

    }
} */